<template>
  <section v-if="slider_news && slider_news.length" class="main-slider">
    <SliderComponent
      class="home-page__main-slider"
      :items="slider_news"
      v-slot="{ item }"
      :slider-options="mainSliderOptions"
      full-width
      main
    >
      <MainSlideComponent :item="item" />
    </SliderComponent>
    <LastNewsComponent />
  </section>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import MainSlideComponent from "./MainSlideComponent.vue";
import LastNewsComponent from "../../news/components/LastNewsComponent.vue";
export default {
  name: "MainSlider",
  components: {
    LastNewsComponent,
    SliderComponent,
    MainSlideComponent,
  },
  data() {
    return {
      mainSliderOptions: {
        slidesPerView: 1,
        rewind: true,
        autoplay: {
          delay: 6000,
        },
      },
    };
  },
  computed: {
    slider_news() {
      return this.$store.state.home.slider_news?.data;
    },
  },
};
</script>

<style lang="stylus">
.main-slider {
	display: grid
	grid-template-columns: 1fr minmax(300px, 324px)
	grid-gap: 30px
	user-select: none
	width: 100%
	max-width: calc(var(--main-width) + var(--sides-padding) * 2)
	margin: 0 auto
	padding: 0 var(--sides-padding)
	align-items: flex-start
	+below(1200px) {
		grid-template-columns: 1fr
	}
}

.home-page__main-slider {
	height: auto

  & .aside {
    +below(700px) {
      display none
    }
  }
}

.home-page__last-news {
	display grid
	grid-template-columns 1fr 1fr 1fr
	flex-direction column
	gap: 20px
}
</style>
