<template>
  <router-link :to="{ name: 'newsItem', params: { id: item.id } }" v-if="item" class="main-slide">
    <div class="ratio-container">
      <ImgComponent v-if="item.img" class="main-slide__img" :img="item.img" />
    </div>
    <div class="main-slide__content">
      <div class="main-slide__info">
        <span v-if="item.published_at" class="main-slide__date">{{ item.published_at | humanDate }}</span>
        <span v-if="item.title" class="main-slide__title">
          {{ item.title }}
        </span>
      </div>
      <div class="main-slide__actions">
        <button class="main-slide__btn btn btn--lg">Подробнее</button>
      </div>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "MainSlideComponent",
  components: { ImgComponent },
  props: {
    item: Object,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/mixins/maxlines.styl"

.main-slide {
	position: relative
  display: flex
  align-items: center
  gap: 40px
	ratio(1044, 520);

  &__content {
		position: absolute
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
    width: 100%
		height: 100%
		display: flex
    flex-direction: column
    align-items: flex-start
		justify-content: flex-end
    gap: 20px
		background-color: var(--dark_o4)
		color: var(--white)
		padding: 30px
		transition: background-color var(--transition)
		+below(700px) {
			padding: 20px
		}

		&:hover {
			background-color: var(--dark_o5)
		}
	}

	&__info {
		display flex
		flex-direction column
		gap: 8px
		max-width: 77%
		+below(1200px) {
			max-width: 100%
			padding-right 150px
		}
		+below(700px) {
			padding-right 55px
		}
		+below(440px) {
			padding-right 0
		}
	}

  &__date {
    font-weight: 500
		opacity: 0.55
    +below(700px) {
      font-size .875rem
      line-height normal
    }
  }

  &__title {
		font-size: 2rem
		font-weight: 700;
		line-height: 1.275;
		text-align: left;
		maxlines(4);
		+below(700px) {
      font-size 1.25rem
      line-height 1.3
    }
		+below(440px) {
			font-size 1.125rem
		}
  }

  &__description {
    font-size .875rem
    line-height 22px
  }

	&__actions {
		+below(700px) {
			display: none
		}
	}

  &__btn {
    border: 1px solid var(--white)
		color: var(--white)
		border-radius: 30px

		&:hover {
			border-color: var(--main)
		}
  }
}
</style>
