<template>
  <div v-if="document" class="home-document">
    <div class="home-document__header">
      <span v-if="document.published_at" class="home-document__date">
        {{ document.published_at | formatDate }}
      </span>
      <span v-if="document['document_type']" class="home-document__category">
        {{ document["document_type"].title }}
      </span>
    </div>
    <p v-if="document.title" class="home-document__title">{{ document.title }}</p>
    <router-link :to="{ name: 'document', params: { id: document.id } }" class="home-document__btn">
      <span>Прочитать</span>
      <IconComponent name="arrow-right" />
    </router-link>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "HomeDocumentComponent",
  props: {
    document: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"

.home-document {
  display flex
  flex-direction column
  gap 15px
  padding 30px
  border 1px solid var(--gray-dark)
  background url("/static/svg/coat-of-arms.svg")
  background-position 95% 20%
  background-repeat no-repeat
  background-color var(--gray-light)
  max-height 249px

  &__header {
    display flex
    align-items center
    gap 15px
  }

  &__date {
    font-size .875rem
    font-weight 700
    line-height 22px
    color var(--main)
    padding 5px 10px
    background var(--white)
  }

  &__category {
    font-size .875rem
    line-height 22px
    color var(--main_o5)
    padding 5px 10px
    background var(--white)
  }

	&__title {
		maxlines(2)
		min-height: 52px
	}

  &__btn {
    display flex
    align-items center
    gap 14px

    span {
      font-size .875rem
      line-height 22px
    }

    .icon svg {
      max-width 16px
      max-height 16px
    }
  }
}
</style>
