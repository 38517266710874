<template>
  <router-link :to="{ name: 'newsItem', params: { id: data.id } }" class="last-news-item" v-if="data">
    <div class="ratio-container">
      <ImgComponent :img="data.img" class="last-news-item__img" />
    </div>
    <div class="last-news-item__cover">
      <div class="last-news-item__info">
        <span class="last-news-item__title">
          {{ data.title }}
        </span>
        <span v-if="data.published_at" class="last-news-item__date">{{ data.published_at | humanDate }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "LastNewsItemComponent",
  components: { ImgComponent },
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/mixins/maxlines.styl"

.last-news-item {
	ratio(326, 148)
	box-shadow: 0 4px 4px 0 #00000020;
	+between(621px, 1200px) {
		ratio(326, 180)
	}

	&:hover &__cover {
		background-color: var(--dark_o7)
	}

	&:hover &__info {
		transform: translateY(0)
		opacity: 1
	}

	&__cover {
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		z-index: 1
		padding: 20px 24px
		display: flex
		flex-direction column
		justify-content flex-end
		color: var(--white)
		background-image: linear-gradient(176.82deg, rgba(0, 0, 0, 0) 2.63%, #000000 97.37%);
		transition: var(--transition)
		+between(841px, 900px) {
			padding: 10px
		}
	}

	&__info {
		display: flex
		flex-direction column
		gap: 4px
		transition transform var(--transition), opacity var(--transition)
		+above(841px) {
			transform: translateY(5px)
			opacity: 0
		}
	}

	&__title {
		font-size: 0.875rem
		font-weight: 700;
		line-height: 1.26rem
		text-align: left;
		maxlines(4)
	}

	&__date {
		opacity: 0.56
		font-size: 0.75rem
		font-weight: 600;
		line-height: 22px;
		text-align: left;
	}
}
</style>
