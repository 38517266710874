<template>
  <main class="home-page">
    <MainSlider />
    <NewsSection />
    <HomeDocumentsSection />
    <ProjectsSlider />
    <!--    <MapSection />-->
    <!--    <MediaSection />-->
    <PopularSection />
    <WidgerSlider />
    <UsefulSlider />
  </main>
</template>

<script>
// import MapSection from "./components/MapSection.vue";
import NewsSection from "./components/NewsSection.vue";
import MainSlider from "./components/MainSlider.vue";
import ProjectsSlider from "./components/ProjectsSlider.vue";
import MediaSection from "./components/MediaSection.vue";
import PopularSection from "./components/PopularSection.vue";
import UsefulSlider from "./components/UsefulSlider.vue";
import HomeDocumentsSection from "./components/HomeDocumentSection.vue";
import WidgerSlider from "components/WidgetSlider.vue";
import DEPARTMENT_NEWS from "./queries/department_news.graphql";
import LINKS from "./queries/links.graphql";
import ORDER_DOCUMENTS from "./queries/order_documents.graphql";
import PHOTO_NEWS from "./queries/photo_news.graphql";
import PROJECTS_PAGINATE from "./queries/projects_paginate.graphql";
import RESOLUTION_DOCUMENTS from "./queries/resolution_documents.graphql";
import SLIDER_NEWS from "./queries/slider_news.graphql";
import LAST_NEWS from "./queries/last_news.graphql";
import VIDEO_NEWS from "./queries/video_news.graphql";
import WIDGETS from "./queries/widgets.graphql";

export default {
  name: "HomePage",
  async asyncData({ apollo, store }) {
    let department_news = apollo.defaultClient.query({
      query: DEPARTMENT_NEWS,
    });
    let links = apollo.defaultClient.query({
      query: LINKS,
    });
    let order_documents = apollo.defaultClient.query({
      query: ORDER_DOCUMENTS,
    });
    let photo_news = apollo.defaultClient.query({
      query: PHOTO_NEWS,
    });
    let projects_paginate = apollo.defaultClient.query({
      query: PROJECTS_PAGINATE,
    });
    let resolution_documents = apollo.defaultClient.query({
      query: RESOLUTION_DOCUMENTS,
    });
    let slider_news = apollo.defaultClient.query({
      query: SLIDER_NEWS,
    });
    let last_news = apollo.defaultClient.query({
      query: LAST_NEWS,
    });
    let video_news = apollo.defaultClient.query({
      query: VIDEO_NEWS,
    });
    let widgets = apollo.defaultClient.query({
      query: WIDGETS,
    });
    await Promise.all([
      slider_news,
      last_news,
      links,
      department_news,
      order_documents,
      photo_news,
      projects_paginate,
      resolution_documents,
      video_news,
      widgets,
    ])
      .then((res) => {
        let data = {};
        res.forEach((promise) => {
          Object.keys(promise.data).forEach((key) => {
            data[key] = promise.data[key];
          });
        });
        store.dispatch("home/save", data);
      })
      .catch(() => {});
    // await apollo.defaultClient
    //   .query({
    //     query: HOME_PAGE,
    //   })
    //   .then(({ data }) => {
    //     store.dispatch("home/save", data);
    //   })
    //   .catch(() => {});
  },
  components: {
    WidgerSlider,
    HomeDocumentsSection,
    NewsSection,
    MainSlider,
    ProjectsSlider,
    // MapSection,
    MediaSection,
    PopularSection,
    UsefulSlider,
  },
};
</script>

<style lang="stylus">
.home-page {
  padding 50px 0
  display flex
  flex-direction column
  gap 60px
  +below(700px) {
    gap 30px
    background var(--gray)
    padding 30px 0
  }
}
</style>
