<template>
  <section class="home-documents">
    <div class="home-documents__container container-padding">
      <BaseArticle title="Распоряжения и постановления" :route="{ name: 'documents' }" />
      <div class="home-documents__content">
        <BaseAside :link="{ name: 'documents' }">
          <span>Смотреть все</span>
          <IconComponent name="right-arrow" />
        </BaseAside>
        <div class="home-documents__list">
          <div v-if="order_documents && order_documents.length" class="home-documents__list--wrapper">
            <HomeDocumentComponent v-for="(document, i) in order_documents" :key="i" :document="document" />
          </div>
          <div
            v-if="resolution_documents && resolution_documents.length"
            class="home-documents__list--wrapper"
          >
            <HomeDocumentComponent
              v-for="(document, i) in resolution_documents"
              :key="i"
              :document="document"
            />
          </div>
          <p v-else>В данный момент информация отсутствует</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseArticle from "components/UI/BaseArticle.vue";
import BaseAside from "components/UI/BaseAside.vue";
import IconComponent from "components/IconComponent.vue";
import HomeDocumentComponent from "./HomeDocumentComponent.vue";
export default {
  name: "HomeDocumentsSection",
  computed: {
    order_documents() {
      return this.$store.state.home.order_documents?.data;
    },
    resolution_documents() {
      return this.$store.state.home.resolution_documents?.data;
    },
  },
  components: {
    HomeDocumentComponent,
    BaseArticle,
    BaseAside,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.home-documents {
  padding-top 30px
  padding-bottom 50px
  background var(--white)
  +below(700px) {
    background var(--gray)
  }

  & .aside {
    +below(700px) {
      background var(--gray-light)
    }
  }

  &__container {
    display flex
    flex-direction column
    gap 40px
    +below(700px) {
      gap 15px
    }
  }

  &__content {
    display flex
    gap 40px
    +below(700px) {
      flex-direction column
      gap 15px
    }
  }

  &__list {
    display grid
    grid-template-columns 1fr 1fr
    //flex-direction column
    grid-gap 40px
    //display grid
    //grid-template-columns repeat(2, 1fr)
    //grid-gap 50px 40px
    width 100%
    +below(1405px) {
      //flex-direction column
      grid-template-columns 1fr
    }
    //+below(900px) {
    //  grid-template-columns 1fr
    //  grid-gap 15px
    //}
		+below(480px) {
			grid-gap 20px
		}

    &--wrapper {
      //display flex
      display grid
      //align-items center
      //flex-direction column
      grid-gap 50px
			+below(480px) {
				grid-gap: 20px
			}
    }
  }
}
</style>
