<template>
  <div class="arrows" :class="{ 'arrows--white': white, 'arrows--main': main }">
    <button
      type="button"
      class="arrows__button arrows__button--left"
      @click.stop="$emit('left')"
      :disabled="current === 1"
    >
      <IconComponent name="left_arrow" />
    </button>
    <div class="arrows__info">
      <template v-if="main">
        <span>{{ "0" + current }}</span>
        <span class="arrows__info-max" v-if="max">/{{ "0" + max }}</span>
      </template>
      <span v-else>{{ current }}</span>
    </div>
    <button
      type="button"
      class="arrows__button arrows__button--right"
      @click.stop="$emit('right')"
      :disabled="current === max"
    >
      <IconComponent name="left_arrow" />
    </button>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "SliderArrowsComponent",
  props: {
    current: Number,
    max: Number,
    white: Boolean,
    main: Boolean,
  },
  mounted() {},
  data() {
    return {};
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.arrows {
  display flex
  align-items center
  gap 35px

  &--white {
    & ^[0]__button {
      background var(--white)
      border-color var(--gray-dark)

      .icon svg path {
        fill var(--gray-dark)
      }

      &--right .icon svg path {
        fill var(--main)
      }
      &--left .icon svg path {
        fill var(--main)
      }
    }

    & ^[0]__info {
      color var(--dark-light)
    }

    & ^[0]__line {
      background var(--dark)
    }
  }

	&--main {
		gap: 0
		color: var(--white)
	}

  &__button {
    cursor pointer
    border: 1px solid var(--dark-light);
    border-radius: 100px;
    background transparent
    width 50px
    height 50px
    padding 0
    display inline-flex
    align-items center
    justify-content center
    position relative
		transition background-color var(--transition)

		^[0]--main & {
			width: 44px
			height: 44px
			+below(700px) {
				display: none
			}

			&:hover {
				background-color: var(--white-o2)
			}

			&[disabled] {
				pointer-events: none
				opacity: 0.56
			}
		}

    .icon {
      width 20px
      height 20px

			^[0]--main & {
				width 24px
				height 24px
			}

      svg path {
        fill var(--white)

				^[0]--main & {
					fill var(--white)
				}
      }
    }
  }

  &__button--right {
    transform rotate(90deg)
    border none

    .icon svg path {
      fill var(--main)

			^[0]--main & {

			}
    }
  }

  &__button--left {
    transform rotate(-90deg)
    border none

    .icon svg path {
      fill var(--main)
    }
  }

  &__info {
    transform rotate(90deg)
    font-weight: 700;
    font-size: 0.875em;
    line-height: 20px;
    min-width: 15px;
    height: 20px;
    color: var(--main);
    display flex
    justify-content center
    align-items center
    gap 5px

		^[0]--main & {
			transform: none
			font-weight: 700
			color: var(--white)
			gap: 4px
			width: 54px
		}

		&-max {
			opacity: 0.56
			font-weight: 500
		}
  }
}
</style>
