<template>
  <div class="last-news" v-if="last_news && last_news.length">
    <router-link :to="{ name: 'news' }">
      <h2 class="last-news__title">
        Новости
        <IconComponent name="arrow-right" />
      </h2>
    </router-link>
    <div class="last-news__list">
      <LastNewsItemComponent v-for="item in last_news" :key="item.id" :data="item" />
    </div>
  </div>
</template>

<script>
import LastNewsItemComponent from "./LastNewsItemComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "LastNewsComponent",
  components: { IconComponent, LastNewsItemComponent },
  computed: {
    last_news() {
      return this.$store.state.home.last_news?.data;
    },
  },
};
</script>

<style lang="stylus">
.last-news {
	display flex
	flex-direction column
	gap: 10px

	&__title {
		display flex
		align-items center
		gap: 12px

		.icon {
			width: 24px
			height: 24px

			svg path {
				fill: var(--gray-2)
				transition: fill var(--transition)
			}
		}

		&:hover .icon svg path {
			fill: currentColor
		}
	}

	&__list {
		display flex
		flex-direction column
		gap: 10px
		+below(1200px) {
			display grid
			grid-template-columns repeat(3, 1fr)
		}
		+below(840px) {
			grid-template-columns repeat(2, 1fr)
		}
		+below(620px) {
			grid-template-columns 1fr
		}
	}
}
</style>
