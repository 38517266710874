<template>
  <section class="home-popular">
    <div class="home-popular__container container-padding">
      <BaseArticle title="Часто просматривают" />
      <div class="home-popular__content">
        <div class="home-popular__empty"></div>
        <div class="home-popular__list">
          <router-link :to="item.to" v-for="(item, i) in items" :key="i" class="home-popular__item">
            <div class="home-popular__item-icon">
              <IconComponent :name="item.icon" />
            </div>
            <span class="home-popular__item-title">{{ item.title }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseArticle from "components/UI/BaseArticle.vue";
import IconComponent from "components/IconComponent.vue";
export default {
  name: "PopularLinksSection",
  data() {
    return {
      items: [
        {
          title: "Структура правительства",
          icon: "hierarchy",
          to: { name: "persons" },
        },
        {
          title: "Прием граждан",
          icon: "chat",
          to: { name: "appeals-form" },
        },
        {
          title: "Документы",
          icon: "documents",
          to: { name: "documents" },
        },
        {
          title: "Контакты",
          icon: "envelope",
          to: { name: "contacts" },
        },
      ],
    };
  },
  components: {
    BaseArticle,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.home-popular {

  &__container {
    display flex
    flex-direction column
    gap 40px
    +below(700px) {
      gap 15px
    }
  }

  &__content {
    display flex
    gap 40px
  }

  &__empty {
    max-width 80px
    width 100%
    +below(900px) {
      display none
    }
  }

  &__list {
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 40px
    width 100%
    +below(1200px) {
      grid-template-columns repeat(3, 1fr)
      grid-gap 20px
    }
    +below(900px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(900px) {
      grid-template-columns 1fr
    }
  }

  &__item {
    background var(--white)
    border 1px solid var(--gray)
    display flex
    align-items center
    gap 11px
    padding 20px 15px
  }

  &__item-icon {
    display flex
    justify-content center
    align-items center
    width 80px
    height 80px

    .icon {
      width 50px
      height 50px

      svg path {
        fill var(--main)
      }
    }
  }

  &__item-title {
    font-weight: 700;
    font-size: 1.125em;
    line-height: 26px;
    color var(--dark)
  }
}
</style>
